
/*

@fontface {
  font-family: 'Bungee Inline', cursive;
  src: url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap')
}

body{
  font-family: 'Quicksand', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/


.da-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  font-family: 'BebasNeueRegular', 'Arial Narrow', Arial, sans-serif;
  text-align: left;
}

.da-slide-current {
  z-index: 10;
}

.da-slider-fb .da-slide {
  left: 100%;
}

  .da-slider-fb .da-slide.da-slide-current {
      left: 0px;
  }

.da-slide h1,
.da-slide p,
.da-slide .da-link,
.da-slide .da-img {
  position: absolute;
  opacity: 0;
  left: 110%;
}

.da-slider-fb .da-slide h1,
.da-slider-fb .da-slide p,
.da-slider-fb .da-slide .da-link {
  left: 10%;
  opacity: 1;
}

.da-slider-fb .da-slide .da-img {
  left: 60%;
  opacity: 1;
}

.da-slide h1 {
  font-family: 'Open Sans', sans-serif;
  color: #f9f9f9 !important;
  line-height: 50px;
  top: 100px;
  z-index: 10;
  padding: 0;
  margin-left: -2%;
  font-weight: 400 !important;
}

  .da-slide h1 i {
      font-size: 36px;
      font-style: normal;
      padding: 0 5px;
  /*background-color: #376092;*/
  text-transform: capitalize;
  }

.da-slide p {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  width: 100%;
  top: 300px;
  color: #fff;
  overflow: hidden;
  margin-left: -2%;
}

  .da-slide p i {
      background-color: #376092;
      padding: 0 10px;
      font-style: normal;
  }

.da-slide .da-img {
  text-align: center;
  width: 50%;
  top: 80px;
  height: 328px;
  line-height: 320px;
  left: 110%; /*60%*/
  margin-left: -16%;
}

.da-slide-current .da-img {
  left: 60%;
  opacity: 1;
}

/*.da-slide .da-link{
top: 320px;
border-radius: 3px !important;
min-width:15%;
color: #666;
text-shadow:0 1px 0 #eee;
box-shadow: 0 0 4px #bbb;
border: 1px solid #fff;
padding: 2px 20px;
font-size: 25px;
text-align: center;
background: rgba(255,255,255,0.2);
}*/

.da-slide .da-link {
  top: 340px; /*LJ got rid of all the junk above use bootstrap buttons*/
}

  .da-slide .da-link:hover {
      background: rgba(255,255,255,0.3);
      box-shadow: 0 0 5px #eee;
      text-decoration: none;
  }

.da-dots {
  width: 100%;
  position: absolute;
  text-align: center;
  left: 0px;
  bottom: 15px;
  z-index: 1;
  -moz-user-select: none;
  -webkit-user-select: none;
}

  /*.da-dots span {
      display: inline-block;
      position: relative;
      width: 12px;
      height: 12px;
      border-radius: 50% !important;
      background: url(../img/bg-text.png) repeat;
      margin: 3px;
      cursor: pointer;
  }*/

      .da-dots span.da-dots-current:after {
          content: '';
          width: 8px;
          height: 8px;
          position: absolute;
          top: 2px;
          left: 2px;
          border-radius: 50% !important;
          background: #fff;
      }

.da-arrows {
  -moz-user-select: none;
  -webkit-user-select: none;
}

  .da-arrows span {
      /*background: transparent url(../img/arrows.png) no-repeat;*/
      position: absolute;
      top: 50%;
      height: 50px;
      width: 49px;
      margin-top: -25px;
      cursor: pointer;
      z-index: 10;
      opacity: 0;
      -webkit-transition: opacity 0.4s ease-in-out 0.2s;
      -moz-transition: opacity 0.4s ease-in-out 0.2s;
      -o-transition: opacity 0.4s ease-in-out 0.2s;
      -ms-transition: opacity 0.4s ease-in-out 0.2s;
      transition: opacity 0.4s ease-in-out 0.2s;
  }

.da-slider:hover .da-arrows span {
  opacity: 0.8;
}

  .da-slider:hover .da-arrows span:hover {
      opacity: 1;
  }

.da-arrows span:after {
  content: '';
  position: absolute;
  width: 49px;
  height: 49px;
}

.da-arrows span.da-arrows-prev {
  left: 12px;
  background-position: 0 -68px;
}

.da-arrows span.da-arrows-next {
  right: 15px;
  background-position: 0 1px;
}

.da-slide-current h1,
.da-slide-current p,
.da-slide-current .da-link {
  left: 10%;
  opacity: 1;
}

.da-slide p {
  z-index: 10;
}

/* Animation classes and animations */

/* Slide in from the right*/
.da-slide-fromright h1 {
  -webkit-animation: fromRightAnim1 0.6s ease-in 0.8s both;
  -moz-animation: fromRightAnim1 0.6s ease-in 0.8s both;
  -o-animation: fromRightAnim1 0.6s ease-in 0.8s both;
  -ms-animation: fromRightAnim1 0.6s ease-in 0.8s both;
  animation: fromRightAnim1 0.6s ease-in 0.8s both;
}

.da-slide-fromright p {
  -webkit-animation: fromRightAnim2 0.6s ease-in 0.8s both;
  -moz-animation: fromRightAnim2 0.6s ease-in 0.8s both;
  -o-animation: fromRightAnim2 0.6s ease-in 0.8s both;
  -ms-animation: fromRightAnim2 0.6s ease-in 0.8s both;
  animation: fromRightAnim2 0.6s ease-in 0.8s both;
}

.da-slide-fromright .da-link {
  -webkit-animation: fromRightAnim3 0.4s ease-in 1.2s both;
  -moz-animation: fromRightAnim3 0.4s ease-in 1.2s both;
  -o-animation: fromRightAnim3 0.4s ease-in 1.2s both;
  -ms-animation: fromRightAnim3 0.4s ease-in 1.2s both;
  animation: fromRightAnim3 0.4s ease-in 1.2s both;
}

.da-slide-fromright .da-img {
  -webkit-animation: fromRightAnim4 0.6s ease-in 0.8s both;
  -moz-animation: fromRightAnim4 0.6s ease-in 0.8s both;
  -o-animation: fromRightAnim4 0.6s ease-in 0.8s both;
  -ms-animation: fromRightAnim4 0.6s ease-in 0.8s both;
  animation: fromRightAnim4 0.6s ease-in 0.8s both;
}

@-webkit-keyframes fromRightAnim1 {
  0% {
      left: 110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-webkit-keyframes fromRightAnim2 {
  0% {
      left: 110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-webkit-keyframes fromRightAnim3 {
  0% {
      left: 110%;
      opacity: 0;
  }

  1% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-webkit-keyframes fromRightAnim4 {
  0% {
      left: 110%;
      opacity: 0;
  }

  100% {
      left: 60%;
      opacity: 1;
  }
}

@-moz-keyframes fromRightAnim1 {
  0% {
      left: 110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-moz-keyframes fromRightAnim2 {
  0% {
      left: 110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-moz-keyframes fromRightAnim3 {
  0% {
      left: 110%;
      opacity: 0;
  }

  1% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-moz-keyframes fromRightAnim4 {
  0% {
      left: 110%;
      opacity: 0;
  }

  100% {
      left: 60%;
      opacity: 1;
  }
}

@-o-keyframes fromRightAnim1 {
  0% {
      left: 110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-o-keyframes fromRightAnim2 {
  0% {
      left: 110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-o-keyframes fromRightAnim3 {
  0% {
      left: 110%;
      opacity: 0;
  }

  1% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-o-keyframes fromRightAnim4 {
  0% {
      left: 110%;
      opacity: 0;
  }

  100% {
      left: 60%;
      opacity: 1;
  }
}

@-ms-keyframes fromRightAnim1 {
  0% {
      left: 110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-ms-keyframes fromRightAnim2 {
  0% {
      left: 110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-ms-keyframes fromRightAnim3 {
  0% {
      left: 110%;
      opacity: 0;
  }

  1% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-ms-keyframes fromRightAnim4 {
  0% {
      left: 110%;
      opacity: 0;
  }

  100% {
      left: 60%;
      opacity: 1;
  }
}

@keyframes fromRightAnim1 {
  0% {
      left: 110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@keyframes fromRightAnim2 {
  0% {
      left: 110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@keyframes fromRightAnim3 {
  0% {
      left: 110%;
      opacity: 0;
  }

  1% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@keyframes fromRightAnim4 {
  0% {
      left: 110%;
      opacity: 0;
  }

  100% {
      left: 60%;
      opacity: 1;
  }
}
/* Slide in from the left*/
.da-slide-fromleft h1 {
  -webkit-animation: fromLeftAnim1 0.6s ease-in 0.6s both;
  -moz-animation: fromLeftAnim1 0.6s ease-in 0.6s both;
  -o-animation: fromLeftAnim1 0.6s ease-in 0.6s both;
  -ms-animation: fromLeftAnim1 0.6s ease-in 0.6s both;
  animation: fromLeftAnim1 0.6s ease-in 0.6s both;
}

.da-slide-fromleft p {
  -webkit-animation: fromLeftAnim2 0.6s ease-in 0.6s both;
  -moz-animation: fromLeftAnim2 0.6s ease-in 0.6s both;
  -o-animation: fromLeftAnim2 0.6s ease-in 0.6s both;
  -ms-animation: fromLeftAnim2 0.6s ease-in 0.6s both;
  animation: fromLeftAnim2 0.6s ease-in 0.6s both;
}

.da-slide-fromleft .da-link {
  -webkit-animation: fromLeftAnim3 0.4s ease-in 1.2s both;
  -moz-animation: fromLeftAnim3 0.4s ease-in 1.2s both;
  -o-animation: fromLeftAnim3 0.4s ease-in 1.2s both;
  -ms-animation: fromLeftAnim3 0.4s ease-in 1.2s both;
  animation: fromLeftAnim3 0.4s ease-in 1.2s both;
}

.da-slide-fromleft .da-img {
  -webkit-animation: fromLeftAnim4 0.6s ease-in 0.6s both;
  -moz-animation: fromLeftAnim4 0.6s ease-in 0.6s both;
  -o-animation: fromLeftAnim4 0.6s ease-in 0.6s both;
  -ms-animation: fromLeftAnim4 0.6s ease-in 0.6s both;
  animation: fromLeftAnim4 0.6s ease-in 0.6s both;
}

@-webkit-keyframes fromLeftAnim1 {
  0% {
      left: -110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-webkit-keyframes fromLeftAnim2 {
  0% {
      left: -110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-webkit-keyframes fromLeftAnim3 {
  0% {
      left: -110%;
      opacity: 0;
  }

  1% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-webkit-keyframes fromLeftAnim4 {
  0% {
      left: -110%;
      opacity: 0;
  }

  100% {
      left: 60%;
      opacity: 1;
  }
}

@-moz-keyframes fromLeftAnim1 {
  0% {
      left: -110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-moz-keyframes fromLeftAnim2 {
  0% {
      left: -110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-moz-keyframes fromLeftAnim3 {
  0% {
      left: -110%;
      opacity: 0;
  }

  1% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-moz-keyframes fromLeftAnim4 {
  0% {
      left: -110%;
      opacity: 0;
  }

  100% {
      left: 60%;
      opacity: 1;
  }
}

@-o-keyframes fromLeftAnim1 {
  0% {
      left: -110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-o-keyframes fromLeftAnim2 {
  0% {
      left: -110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-o-keyframes fromLeftAnim3 {
  0% {
      left: -110%;
      opacity: 0;
  }

  1% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-o-keyframes fromLeftAnim4 {
  0% {
      left: -110%;
      opacity: 0;
  }

  100% {
      left: 60%;
      opacity: 1;
  }
}

@-ms-keyframes fromLeftAnim1 {
  0% {
      left: -110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-ms-keyframes fromLeftAnim2 {
  0% {
      left: -110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-ms-keyframes fromLeftAnim3 {
  0% {
      left: -110%;
      opacity: 0;
  }

  1% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@-ms-keyframes fromLeftAnim4 {
  0% {
      left: -110%;
      opacity: 0;
  }

  100% {
      left: 60%;
      opacity: 1;
  }
}

@keyframes fromLeftAnim1 {
  0% {
      left: -110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@keyframes fromLeftAnim2 {
  0% {
      left: -110%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@keyframes fromLeftAnim3 {
  0% {
      left: -110%;
      opacity: 0;
  }

  1% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: 10%;
      opacity: 1;
  }
}

@keyframes fromLeftAnim4 {
  0% {
      left: -110%;
      opacity: 0;
  }

  100% {
      left: 60%;
      opacity: 1;
  }
}
/* Slide out to the right */
.da-slide-toright h1 {
  -webkit-animation: toRightAnim1 0.6s ease-in 0.6s both;
  -moz-animation: toRightAnim1 0.6s ease-in 0.6s both;
  -o-animation: toRightAnim1 0.6s ease-in 0.6s both;
  -ms-animation: toRightAnim1 0.6s ease-in 0.6s both;
  animation: toRightAnim1 0.6s ease-in 0.6s both;
}

.da-slide-toright p {
  -webkit-animation: toRightAnim2 0.6s ease-in 0.3s both;
  -moz-animation: toRightAnim2 0.6s ease-in 0.3s both;
  -o-animation: toRightAnim2 0.6s ease-in 0.3s both;
  -ms-animation: toRightAnim2 0.6s ease-in 0.3s both;
  animation: toRightAnim2 0.6s ease-in 0.3s both;
}

.da-slide-toright .da-link {
  -webkit-animation: toRightAnim3 0.4s ease-in both;
  -moz-animation: toRightAnim3 0.4s ease-in both;
  -o-animation: toRightAnim3 0.4s ease-in both;
  -ms-animation: toRightAnim3 0.4s ease-in both;
  animation: toRightAnim3 0.4s ease-in both;
}

.da-slide-toright .da-img {
  -webkit-animation: toRightAnim4 0.6s ease-in both;
  -moz-animation: toRightAnim4 0.6s ease-in both;
  -o-animation: toRightAnim4 0.6s ease-in both;
  -ms-animation: toRightAnim4 0.6s ease-in both;
  animation: toRightAnim4 0.6s ease-in both;
}

@-webkit-keyframes toRightAnim1 {
  0% {
      left: 10%;
      opacity: 1;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@-webkit-keyframes toRightAnim2 {
  0% {
      left: 10%;
      opacity: 1;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@-webkit-keyframes toRightAnim3 {
  0% {
      left: 10%;
      opacity: 1;
  }

  99% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@-webkit-keyframes toRightAnim4 {
  0% {
      left: 60%;
      opacity: 1;
  }

  30% {
      left: 55%;
      opacity: 1;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@-moz-keyframes toRightAnim1 {
  0% {
      left: 10%;
      opacity: 1;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@-moz-keyframes toRightAnim2 {
  0% {
      left: 10%;
      opacity: 1;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@-moz-keyframes toRightAnim3 {
  0% {
      left: 10%;
      opacity: 1;
  }

  99% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@-moz-keyframes toRightAnim4 {
  0% {
      left: 60%;
      opacity: 1;
  }

  30% {
      left: 55%;
      opacity: 1;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@-o-keyframes toRightAnim1 {
  0% {
      left: 10%;
      opacity: 1;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@-o-keyframes toRightAnim2 {
  0% {
      left: 10%;
      opacity: 1;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@-o-keyframes toRightAnim3 {
  0% {
      left: 10%;
      opacity: 1;
  }

  99% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@-o-keyframes toRightAnim4 {
  0% {
      left: 60%;
      opacity: 1;
  }

  30% {
      left: 55%;
      opacity: 1;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@-ms-keyframes toRightAnim1 {
  0% {
      left: 10%;
      opacity: 1;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@-ms-keyframes toRightAnim2 {
  0% {
      left: 10%;
      opacity: 1;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@-ms-keyframes toRightAnim3 {
  0% {
      left: 10%;
      opacity: 1;
  }

  99% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@-ms-keyframes toRightAnim4 {
  0% {
      left: 60%;
      opacity: 1;
  }

  30% {
      left: 55%;
      opacity: 1;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@keyframes toRightAnim1 {
  0% {
      left: 10%;
      opacity: 1;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@keyframes toRightAnim2 {
  0% {
      left: 10%;
      opacity: 1;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@keyframes toRightAnim3 {
  0% {
      left: 10%;
      opacity: 1;
  }

  99% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}

@keyframes toRightAnim4 {
  0% {
      left: 60%;
      opacity: 1;
  }

  30% {
      left: 55%;
      opacity: 1;
  }

  100% {
      left: 100%;
      opacity: 0;
  }
}
/* Slide out to the left*/
.da-slide-toleft h1 {
  -webkit-animation: toLeftAnim1 0.6s ease-in both;
  -moz-animation: toLeftAnim1 0.6s ease-in both;
  -o-animation: toLeftAnim1 0.6s ease-in both;
  -ms-animation: toLeftAnim1 0.6s ease-in both;
  animation: toLeftAnim1 0.6s ease-in both;
}

.da-slide-toleft p {
  -webkit-animation: toLeftAnim2 0.6s ease-in 0.3s both;
  -moz-animation: toLeftAnim2 0.6s ease-in 0.3s both;
  -o-animation: toLeftAnim2 0.6s ease-in 0.3s both;
  -ms-animation: toLeftAnim2 0.6s ease-in 0.3s both;
  animation: toLeftAnim2 0.6s ease-in 0.3s both;
}

.da-slide-toleft .da-link {
  -webkit-animation: toLeftAnim3 0.4s ease-in both;
  -moz-animation: toLeftAnim3 0.4s ease-in both;
  -o-animation: toLeftAnim3 0.4s ease-in both;
  -ms-animation: toLeftAnim3 0.4s ease-in both;
  animation: toLeftAnim3 0.4s ease-in both;
}

.da-slide-toleft .da-img {
  -webkit-animation: toLeftAnim4 0.6s ease-in 0.6s both;
  -moz-animation: toLeftAnim4 0.6s ease-in 0.6s both;
  -o-animation: toLeftAnim4 0.6s ease-in 0.6s both;
  -ms-animation: toLeftAnim4 0.6s ease-in 0.6s both;
  animation: toLeftAnim4 0.6s ease-in 0.6s both;
}

@-webkit-keyframes toLeftAnim1 {
  0% {
      left: 10%;
      opacity: 1;
  }

  30% {
      left: 15%;
      opacity: 1;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@-webkit-keyframes toLeftAnim2 {
  0% {
      left: 10%;
      opacity: 1;
  }

  30% {
      left: 15%;
      opacity: 1;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@-webkit-keyframes toLeftAnim3 {
  0% {
      left: 10%;
      opacity: 1;
  }

  99% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@-webkit-keyframes toLeftAnim4 {
  0% {
      left: 60%;
      opacity: 1;
  }

  40% {
      left: 70%;
      opacity: 1;
  }

  90% {
      left: 0%;
      opacity: 0;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@-moz-keyframes toLeftAnim1 {
  0% {
      left: 10%;
      opacity: 1;
  }

  30% {
      left: 15%;
      opacity: 1;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@-moz-keyframes toLeftAnim2 {
  0% {
      left: 10%;
      opacity: 1;
  }

  30% {
      left: 15%;
      opacity: 1;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@-moz-keyframes toLeftAnim3 {
  0% {
      left: 10%;
      opacity: 1;
  }

  99% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@-moz-keyframes toLeftAnim4 {
  0% {
      left: 60%;
      opacity: 1;
  }

  40% {
      left: 70%;
      opacity: 1;
  }

  90% {
      left: 0%;
      opacity: 0;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@-o-keyframes toLeftAnim1 {
  0% {
      left: 10%;
      opacity: 1;
  }

  30% {
      left: 15%;
      opacity: 1;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@-o-keyframes toLeftAnim2 {
  0% {
      left: 10%;
      opacity: 1;
  }

  30% {
      left: 15%;
      opacity: 1;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@-o-keyframes toLeftAnim3 {
  0% {
      left: 10%;
      opacity: 1;
  }

  99% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@-o-keyframes toLeftAnim4 {
  0% {
      left: 60%;
      opacity: 1;
  }

  40% {
      left: 70%;
      opacity: 1;
  }

  90% {
      left: 0%;
      opacity: 0;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@-ms-keyframes toLeftAnim1 {
  0% {
      left: 10%;
      opacity: 1;
  }

  30% {
      left: 15%;
      opacity: 1;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@-ms-keyframes toLeftAnim2 {
  0% {
      left: 10%;
      opacity: 1;
  }

  30% {
      left: 15%;
      opacity: 1;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@-ms-keyframes toLeftAnim3 {
  0% {
      left: 10%;
      opacity: 1;
  }

  99% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@-ms-keyframes toLeftAnim4 {
  0% {
      left: 60%;
      opacity: 1;
  }

  40% {
      left: 70%;
      opacity: 1;
  }

  90% {
      left: 0%;
      opacity: 0;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@keyframes toLeftAnim1 {
  0% {
      left: 10%;
      opacity: 1;
  }

  30% {
      left: 15%;
      opacity: 1;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@keyframes toLeftAnim2 {
  0% {
      left: 10%;
      opacity: 1;
  }

  30% {
      left: 15%;
      opacity: 1;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@keyframes toLeftAnim3 {
  0% {
      left: 10%;
      opacity: 1;
  }

  99% {
      left: 10%;
      opacity: 0;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

@keyframes toLeftAnim4 {
  0% {
      left: 60%;
      opacity: 1;
  }

  40% {
      left: 70%;
      opacity: 1;
  }

  90% {
      left: 0%;
      opacity: 0;
  }

  100% {
      left: -50%;
      opacity: 0;
  }
}

/*Higher Resolutions*/
@media (min-width: 1440px) {
  .da-slide h1,
  .da-slide p {
      margin-left: 3%;
  }

  .da-slide .da-img {
      margin-left: -18%;
  }

  .da-slide .da-link {
      margin-left: 11%
  }
}

@media (min-width: 1680px) {
  .da-slide h1,
  .da-slide p {
      margin-left: 7%;
  }

  .da-slide .da-img {
      margin-left: -20%;
  }

  .da-slide .da-link {
      margin-left: 15%
  }
}

@media (min-width: 1920px) {
  .da-slide h1,
  .da-slide p {
      margin-left: 12%;
  }

  .da-slide .da-img {
      margin-left: -22%;
  }

  .da-slide .da-link {
      margin-left: 20%;
  }
}

  
  
.da-slider{
width: 100%;
height: 427px;
position: relative;
margin: 0 auto;
overflow: hidden;
background: transparent url(/public/img/kaligent-art-3.jpg) repeat 0% 0%;	

box-shadow: 0px 1px 1px rgba(0,0,0,0.2), 0px -2px 1px #fff;
-webkit-transition: background-position 1.4s ease-in-out 0.3s;
-moz-transition: background-position 1.4s ease-in-out 0.3s;
-o-transition: background-position 1.4s ease-in-out 0.3s;
-ms-transition: background-position 1.4s ease-in-out 0.3s;
transition: background-position 1.4s ease-in-out 0.3s;
background-repeat: repeat;
}
  .tag-box {
  margin-left: 10px;
  margin-right: 10px;
}
  .margin-bottom-20 {
  margin-bottom: 20px;
  padding-left: 10px;
}

.margin-top-10 {
  margin-top: 10px;
  padding-left: 10px;
}

.margin-top-20 {
  margin-top: 20px;
  padding-left: 10px;
}

.purchase p {
  margin-bottom: 0;
font-size: 18px;
}

p {
  font-size: 18px;
}
  .headline-center p {
  font-size: 18px;
}
.da-slide p {   
  top: 230px;
}
  
.da-slide h1 {   
  top: 120px;
}

@media (max-width: 450px){
.da-slide h1 {
  top: 80px;
}}
  
@media (max-width: 280px){
.da-slide h1 {
  top: 10px;
}}
  
@media (min-width: 1024px){
.da-slide h1 {
  top: 175px;
}}
  
@media (min-width: 1920px){
.da-slide h1, .da-slide p {
  margin-left: 22%;
}}

.bg-color-light {
  background-color: #ddd!important;
}		

.bg-color-dark {
  background-color: #0c0c0c!important;
}	

.customHeader{
  color:#0C4887;text-transform: capitalize; font-size:23px;
}

.cube-portfolio .cbp-l-grid-agency-desc-more {
  font-size: 13.5px;
  font-family: 'Open Sans',sans-serif;
  background-color: #eee;
  padding-top: 10px;
  padding-bottom: 10px;
}

.facultyImg {
  display: inline;
  max-width: 100%;
  height: auto;
  border-radius: 50%;
  border: 5px solid #ccc;
}

.cube-portfolio .cbp-l-grid-agency-desc-more2 {
  font-size: 13.5px;
  font-family: 'Open Sans',sans-serif;
  background-color: #eee;
  padding-top: 0px;
  padding-bottom: 10px;
}

.serviceBGColor {
  background-color: #375f92;
}

.serviceBGColorSecondary {
  background-color: #9ea2a8;
}

.headerFontColor h2{
  color: #8dc63f;  
}

.headerFontColor h2:after{
  left:50%;
  z-index:1;
  width:30px;
  height:2px;
  content:" ";
  bottom:-5px;
  margin-left:-15px;
  text-align:center;
  position:absolute;
  background:#fff
}

.headerFontColorDark h2{
  color: #8dc63f;  
}

.headerFontColorDark h2:after{
  left:50%;
  z-index:1;
  width:30px;
  height:2px;
  content:" ";
  bottom:-5px;
  margin-left:-15px;
  text-align:center;
  position:absolute;
  background:#555
}

.footerCopyRight {
  padding-top: 30px;
  padding-bottom: 50px;
  font-size: 1.4em;
  color:white;
}

.react-tabs__tab {  
  font-size: 1.555em;
}

.react-tabs__tab-panel {
  font-size: 18px;
}

.react-tabs__tab-panel ul{
  list-style-type: none;
}

ul.checkmark li {
  list-style-type: none; 
  margin-bottom: 1em; 
  padding: 0.25em 0 0 2.5em; 
  position: relative;
  font-size: 16px; 
}

ul.checkmark li:before {
  content: " "; 
  display: block;
  border: solid 0.8em #90c745; 
  border-radius: .8em; 
  height: 0;
  width: 0;
  position: absolute; 
  left: 0.5em;
  top: 40%; 
  margin-top: -0.5em;
  font-size: 24px; 
}

ul.checkmark li:after {
  content: " ";
  display: block;
  width: 0.6em; 
  height: 0.8em;
  border: solid white;
  border-width: 0 0.2em 0.2em 0; 
  position: absolute;
  left: 1em;
  top: 40%;
  margin-top: -0.2em;
  -webkit-transform: rotate(45deg); 
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  font-size: 24px; 
}

.rcornerBox {
  border-radius: 25px;
  border: .095em solid #90c745;
  width: 100%;
  height: 100%;
}

.tabBox {
  background-color: #fff;
}

.kaligentSingleIcon { 
  font-size: 4.5em; 
  color: #90c745; 
}

.kaligentSingleIconSm { 
  font-size: 2.5em; 
  color: #90c745;
  margin-left: 10px;   
}

.kaligentSingleIconSm2 { 
  font-size: 1.5em; 
  color: #90c745;
  margin-left: 10px;   
}

ul.kaligentIcon li {
  list-style-type: none; 
  margin-bottom: 1em; 
  padding: 0em 0em 0em 0em; 
  position: relative;
  font-size: 16px; 
}

.kaligentIconText {
  font-size: 18px;
  color:#0c0c0c;
  margin-left: .5em; 
  position: relative;
  bottom: 5px;
}

.kaligentCard {
  --bs-card-spacer-y: 0rem;
  --bs-card-spacer-x: 0rem;
}

.themeColor {
  color: #8dc63f;  
}

.themeColorDark {
  color: #555;  
}

.parallax-bg-wrapper{
  text-align: "center";
}

.parallax-bg-inside-wrapper{
  background: "white";
  padding: 20px;
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%,-50%)";
  height: 500px;
}

.custom-background {
  width: 100%;
  height: 455px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  background: transparent url(/public/img/left-lines.png) repeat 0% 0%;	
  
  box-shadow: 0px 1px 1px rgba(0,0,0,0.2), 0px -2px 1px #fff;
  -webkit-transition: background-position 1.4s ease-in-out 0.3s;
  -moz-transition: background-position 1.4s ease-in-out 0.3s;
  -o-transition: background-position 1.4s ease-in-out 0.3s;
  -ms-transition: background-position 1.4s ease-in-out 0.3s;
  transition: background-position 1.4s ease-in-out 0.3s;
  background-repeat: repeat;
}

/*.dot {
  height: 25px;
  width: 25px;
  background-color: #90c745;
  border-radius: 50%;
  display: inline-block;
}*/

/*.react-tabs__tab {
 background: #ccc;
}

.react-tabs__tab .react-tabs__tab--selected {
  background: #fff;
}

.react-tabs__tab:focus {
  background: #fff;
}*/

